import React, { useRef } from "react"
import * as D3 from "d3"
import SEO from "../../../components/seo"
import useContainerDimensions from "../../../components/useContainerDimensions"
import worldMap from "../../../components/worldMap"
import styles from "./lactose-intolerance.module.css"
import "../../general.css"
import LactoseIntoleranceData from "../../../data/lactose-intolerance.json"
import legend from "./lactose-intolerance-legend.png"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../../../defines";

const Title = "Lactose malabsorption in adults";

let IntoleranceByCountry;

const LactoseIntoleranceMap = ({ mobile }) => {
    let MapAspectRatio = 5 / 3;
    if (mobile) {
        MapAspectRatio = 1;
    }
    if (!IntoleranceByCountry) {
        IntoleranceByCountry = {};
        LactoseIntoleranceData.countries.map(
            c => IntoleranceByCountry[c.name] = c.value);
    }
    const mapDivRef = useRef();
    const { width } = useContainerDimensions(mapDivRef);
    const mapWidth = width;
    const mapHeight = width / MapAspectRatio;
    const renderMap = () => {
        if (typeof document === "undefined") {
            return;
        }
        const mapSvg = D3.select(".map");
        let worldMapG = mapSvg.select(".worldMapG");
        if (worldMapG.empty()) {
            worldMapG = mapSvg.append("g");
            worldMapG.attr("class", "worldMapG");
        }
        const getCountryTitle = countryName =>
            IntoleranceByCountry[countryName] ?
                `${countryName}: ${Math.round(IntoleranceByCountry[countryName] * 100)}%` :
                countryName
        const getCountryColor = countryName =>
            IntoleranceByCountry[countryName] ?
                D3.interpolateReds(IntoleranceByCountry[countryName]) :
                "black";
        worldMapG.call(worldMap, {
            svg: mapSvg,
            width: mapWidth,
            height: mapHeight,
            countryPathClass: styles.countryPath,
            oceanPathClass: styles.oceanPath,
            tooltipClass: styles.tooltip,
            getCountryTitle,
            getCountryColor,
            mobile
        });
    };

    renderMap();
    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={vizBackgroundStyle(mobile)}>
                    <div className={styles.tooltip} style={{ opacity: 0 }} />
                    <div className="citation" style={{ textAlign: "center", marginBottom: 0, paddingBottom: 0 }}>
                        This map shows per-country lactose intolerance via color. Countries without data are shown as black.
                </div>
                    <div className="FlexGrow" ref={mapDivRef} style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
                        <svg className="map" width={`${mapWidth}px`} height={`${mapHeight}px`} />
                    </div>
                    <div>
                        <img src={legend} alt="Legend" width="100%"/>
                    </div>
                    <div className="Rtable Rtable2cols">
                        <div className="RtableCellLeft">Tolerant</div>
                        <div className="RtableCellRight">Intolerant</div>
                    </div>
                    <div className="citation">
                        Data source: <a href="https://www.thelancet.com/journals/langas/article/PIIS2468-1253(17)30154-1/fulltext">
                            Country, regional, and global estimates for lactose malabsorption in adults: a systematic review and meta-analysis</a>
                    </div>
                </div>
            </div>
        </>
    )
};

export default LactoseIntoleranceMap;
