import * as D3 from "d3"
import * as D3GeoProjection from "d3-geo-projection"
import * as topojson from "topojson-client"

import CountryData50m from "../data/countries-50m.json"

export default (selection, props) => {
    const {
        svg,
        width,
        height,
        countryPathClass,
        oceanPathClass,
        getCountryTitle,
        getCountryColor,
        mobile,
        tooltipClass
    } = props;

    if (width === 0 || height === 0) {
        return;
    }

    const countries = topojson.feature(CountryData50m, "countries");
    const projection = mobile ? 
        D3GeoProjection.geoGilbert().fitSize([width - 3, height - 4], countries) :
        D3.geoNaturalEarth1().fitSize([width, height], countries);
    const pathGenerator = D3.geoPath().projection(projection);

    svg.call(D3.zoom().on("zoom", () => {
        selection.attr("transform", D3.event.transform);
    }));

    if (mobile) {
        selection.attr("transform", "translate(0, 2)")
    }

    selection.selectAll(`.${oceanPathClass}`)
        .data([null])
        .join(enter =>
            enter.append("path")
                .attr("d", pathGenerator({ type: "Sphere" }))
                .attr("class", oceanPathClass),
            update =>
                update.attr("d", pathGenerator({ type: "Sphere" }))
        );

    let tooltipDiv = D3.select(`.${tooltipClass}`);
    console.log(tooltipDiv);
    const setupMouseHandler = selection => {
        selection
            .on("mouseover", function () {
                let s = tooltipDiv.transition()
                    .duration(200)
                    .style("opacity", .9);
                if (mobile) {
                    s.transition()
                    .duration(3000)
                    .style("opacity", 0)
                }
            })
            .on("mousemove", function (d) {
                tooltipDiv.html(getCountryTitle ? getCountryTitle(d.properties.name) : d.properties.name)
                    .style("left", (D3.event.pageX) + "px")
                    .style("top", (D3.event.pageY - 28) + "px");
            })
            .on("mouseout", function () {
                tooltipDiv.transition()
                    .duration(500)
                    .style("opacity", 0)
            });
    };

    selection.selectAll(`.${countryPathClass}`)
        .data(countries.features)
        .join(
            enter =>
                enter.append("path")
                    .attr("d", pathGenerator)
                    .attr("class", countryPathClass)
                    .attr("fill", d => getCountryColor(d.properties.name))
                    .call(setupMouseHandler),
            update =>
                update
                    .attr("d", pathGenerator)
                    .call(setupMouseHandler)
        );
};
